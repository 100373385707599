(function () {
  'use strict';

  class PreviewResourceCtrl {
    constructor($mdDialog, PreviewResource, $mdMedia) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.node = PreviewResource.getObj();
      vm.$mdMedia = $mdMedia;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:PreviewResourceCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('PreviewResourceCtrl', PreviewResourceCtrl);
}());
